.modal {
  left: 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: visibility .4s, opacity .4s ease;
  visibility: hidden;
  width: 100%;
  z-index: 160000011; }
  .modal__body {
    margin-top: 20px; }
    .modal__body.hidden {
      display: none !important; }
  .modal__button {
    background: #DB3645;
    border-color: #DB3645;
    border-radius: 6px;
    border-style: solid;
    color: #FFFFFF;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 14px;
    padding: 8px 5px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: fit-content; }
  .modal__close-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px; }
  .modal__close-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    height: 20px;
    outline: none;
    padding: 0;
    position: relative;
    width: 20px;
    top: 1px; }
    .modal__close-btn:before, .modal__close-btn:after {
      left: 50%;
      position: absolute;
      content: '';
      top: 50%;
      width: 20px;
      height: 2px;
      background-color: #1c1417; }
    .modal__close-btn:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .modal__close-btn:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .modal__close-btn--brown:after, .modal__close-btn--brown:before {
      background: #4F4A47; }
  .modal__container {
    margin: 15px auto;
    max-width: calc(100% - 30px);
    position: relative;
    height: 100%; }
    .modal__container--shadow {
      box-shadow: 0 4px 8px rgba(28, 20, 23, 0.25); }
  .modal__content {
    background: #FFFFFF;
    padding: 20px 30px 40px; }
    .modal__content-newsletter {
      display: flex;
      align-items: center;
      flex-direction: column !important; }
  .modal__header {
    display: flex;
    border-bottom: 1px solid #DCDCDC;
    padding: 10px 0 20px; }
  .modal__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  @media (max-width: 767px) {
    .modal__image-container {
      display: none; } }
  .modal__inner {
    left: 50%;
    max-height: 100%;
    overflow: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
  .modal__input {
    appearance: none;
    border: none;
    border-bottom: 1px solid rgba(79, 74, 71, 0.5);
    color: #4F4A47;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: .03rem;
    line-height: 20px;
    outline: none;
    padding: 7px 12px;
    transition: .25s ease;
    width: 100%; }
    @media (max-width: 767px) {
      .modal__input {
        font-size: 11px;
        line-height: 12px; } }
    .modal__input:focus {
      border-color: #4F4A47;
      transition: .25s ease; }
    .modal__input.wrong {
      border-color: #DB3645;
      transition: .25s ease; }
    .modal__input--hidden {
      display: none; }
  .modal__item-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 30px;
    margin-right: 15px;
    width: 30px; }
  .modal__item-text {
    flex: auto; }
  .modal__label {
    align-items: center;
    display: flex; }
  .modal__link {
    color: #1c1417; }
  .modal__list {
    list-style-type: none;
    margin-top: 20px; }
  .modal__list-item:not(:first-child) {
    margin-top: 20px; }
  .modal__overlay {
    background-color: rgba(28, 20, 23, 0.3);
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
  .modal__text {
    color: #1c1417;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px; }
    .modal__text--small {
      font-size: 14px;
      line-height: 25px; }
  .modal__title {
    color: #1c1417;
    flex: auto;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 30px; }
  .modal.active.modal {
    opacity: 1;
    transition: visibility .4s, opacity .4s ease;
    visibility: visible; }
  @media (max-width: 767px) {
    .modal--mobile-sticky-top .modal__container {
      margin: 0;
      max-width: 100%; } }
  @media (max-width: 767px) {
    .modal--mobile-sticky-top .modal__inner {
      top: 0;
      transform: translate(-50%, 0); } }
